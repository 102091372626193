<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Warehouses</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small to="/inventory/warehouse/create">
          <v-icon left dark>mdi-plus</v-icon> New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="warehouses"
              :search="search"
            >
              <template v-slot:item.action="props">
                <v-btn :to="`/view-warehouse/${props.item.id}`" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.WhsCode="props">
                {{ props.item.WhsCode }}
                <v-btn
                  small
                  v-if="props.item.BinActivat"
                  :to="`/warehouse/bin-location/${props.item.WhsCode}`"
                  icon
                >
                  <v-icon dense color="warning">mdi-arrow-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Name", value: "WhsName" },
      { text: "Code", value: "WhsCode" },
      { text: "Branch", value: "BPLId" },
      { text: "Location", value: "Location" },
      { text: "View Details", value: "action" },
      { text: "BinLocations", value: "WhsCode" },
    ],
    warehouses: [],
  }),
  methods: {
    getWarehouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.warehouses = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
  },
  created() {
    this.getWarehouses();
  },
};
</script>
